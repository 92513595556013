import {
  Component,
  Input,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  AnswerAsArray,
  ContainerAnswer,
  CustomIdSettings,
  CustomIdType,
  LibraryAvailableRelationship,
  OperandType,
  OperatorType,
  Question,
  QuestionAnswer,
  QuestionFieldType,
  RuleEquation,
  RuleModalOperator,
  RuleOperand,
  StationLibraryRelationship,
  UserInfoBasicData,
} from 'src/models';
import { v4 as uuidv4 } from 'uuid';
import { TextFieldComponent } from 'src/app/shared/fields/text-field/text-field.component';
import { NumberFieldComponent } from 'src/app/shared/fields/number-field/number-field.component';
import { DateFieldComponent } from 'src/app/shared/fields/date-field/date-field.component';
import { SelectFieldComponent } from 'src/app/shared/fields/select-field/select-field.component';
import { ContainerService } from 'src/app/core/container.service';
import { PopupService } from 'src/app/core/popup.service';
import { ComponentHelper, StationTerms, TermsGeneric } from 'src/helpers';
import _ from 'lodash';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CheckFieldComponent } from 'src/app/shared/fields/check-field/check-field.component';
import { UserService } from 'src/app/core/user.service';
import { ConditionHelper } from 'src/helpers';
import { UserRelatesComponent } from 'src/app/shared/fields/user-relates/user-relates.component';
import { PowerService } from 'src/app/core/power.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { UtcDateFormatPipe } from 'src/helpers/pipes/utc-date-format/utc-date-format.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';

/**
 *
 */
@Component({
  selector: 'app-conditions[conditions]',
  templateUrl: './conditions.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    TextFieldComponent,
    NumberFieldComponent,
    DateFieldComponent,
    SelectFieldComponent,
    LoadingIndicatorComponent,
    UserRelatesComponent,
    TextFieldComponent,
    UtcDateFormatPipe,
  ],
  styleUrls: ['./conditions.component.scss'],
})
export class ConditionsComponent implements OnInit, OnDestroy {
  /** Helper for conditions. */
  conditionHelper = ConditionHelper;

  /** Helper for Stations. */
  stationTerms = StationTerms;

  /** The component text-field to be updated. */
  @ViewChild('textField', { static: false })
  textField!: TextFieldComponent;

  /** The component number-field to be updated. */
  @ViewChild('numberField', { static: false })
  numberField!: NumberFieldComponent;

  /** The component date-field to be updated. */
  @ViewChild('dateField', { static: false })
  dateField!: DateFieldComponent;

  /** The component select-field to be updated. */
  @ViewChild('selectField', { static: false })
  selectField!: SelectFieldComponent;

  /** The component checkList/radioList field to be updated. */
  @ViewChild('checkField', { static: false })
  checkField!: CheckFieldComponent;

  /** The component user-relates to be updated. */
  @ViewChild('userRelates', { static: false })
  userRelates!: UserRelatesComponent;

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Observable for when the second operand value is cleaned. */
  private cleanSecondOperandField$ = new Subject<void>();

  /** The station id used to get previous fields. */
  @Input() stationRithmId!: string;

  /** Station Libraries.*/
  @Input() stationLibraryRelationships: StationLibraryRelationship[] = [];

  /** Station Libraries is Loading.*/
  @Input() stationLibrariesLoading = false;

  /** Contains all questions associated with the station. */
  @Input() stationQuestionPool: Question[] = [];

  /** Conditions of the current power. */
  @Input() conditions: RuleEquation[] = [];

  /** Feature flag Assigned to conditions. */
  @Input() assignedToConditions = false;

  /** Show button new condition. */
  @Input() showButtonNewCondition = false;

  /** Identify total of condition from component parent. */
  @Input() conditionsCount = 0;

  /** Feature flag order of operations. */
  @Input({ required: true }) orderOfOperations = false;

  /** Rule rithm. */
  @Input({ required: true }) ruleRithmId = '';

  /* A private variable that is used to store the value of the input property. */
  private _refreshIdContainer = '';

  /** This is a setter. It is called when the value of the input property changes. */
  @Input() set refreshIdContainer(value: string) {
    this._refreshIdContainer = value;
    this.openFormCondition = false;
  }

  /** Feature flag to show the rules for Number field in conditions. */
  @Input() showNumberFieldRules = false;

  /** Feature flag for attachment field conditions. */
  @Input() attachmentFieldLogicFlag = false;

  /** Flag to condition attachment. */
  @Input({ required: true }) flagAttachmentsConditionsFilters = false;

  /**
   * It returns the value of the private variable _refreshIdContainer.
   * @returns The value of the new id container.
   */
  get refreshIdContainer(): string {
    return this._refreshIdContainer;
  }

  /**
   * Disabled button save of conditions.
   * @returns Boolean to disabled button.
   */
  get disabledButtonSave(): boolean {
    const isInvalidSecondOperand =
      !this.secondOperand.value.toString().trim() ||
      !this.formSecondOperand.valid;
    const isInvalidOperatorSelected = ![
      OperatorType.IsAnswered,
      OperatorType.NotAnswered,
      OperatorType.IsTrue,
      OperatorType.IsFalse,
    ].includes(this.operatorSelected as OperatorType);

    return (
      !this.operatorSelected ||
      (isInvalidSecondOperand && isInvalidOperatorSelected) ||
      (this.isLoadingUser && !this.isErrorUser)
    );
  }

  /**
   * Disabled button switch of conditions.
   * @returns Boolean to disabled button.
   */
  get disableCustomFieldControl(): boolean {
    return ConditionHelper.disableCustomField(
      this.attachmentFieldLogicFlag,
      this.flagAttachmentsConditionsFilters,
      this.firstOperandQuestionType,
      this.operatorSelected as OperatorType,
      this.customIdType,
    );
  }

  /** If power conditions has been edited, this emit to enable save. */
  @Output() somethingHasChanged = new EventEmitter();

  /** Send condition independent to rules component. */
  @Output() addCondition = new EventEmitter<RuleEquation>();

  /** Send condition deleted. */
  @Output() deleteConditionEmit = new EventEmitter<string>();

  /** List of questions info. */
  questionsInfoList: QuestionFieldType[] = [];

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** List of question field type. */
  questionFieldType = QuestionFieldType;

  /** The default value for the second question if is needed.*/
  secondOperandDefaultQuestion: Question = {
    questionType: QuestionFieldType.ShortText,
    rithmId: uuidv4(),
    prompt: 'Custom',
    isReadOnly: false,
    isRequired: false,
    isPrivate: false,
    value: '',
    children: [],
    possibleAnswers: [],
    answer: {
      questionRithmId: uuidv4(),
      referAttribute: '',
      asArray: [
        {
          value: '',
          isChecked: false,
        },
      ],
      asInt: 0,
      asDecimal: 0,
      asString: '',
      asDate: '',
      value: '',
    },
  };

  /** The type of the first questions selected for the first operand. */
  firstOperandQuestionType!: QuestionFieldType;

  /** The type of the second questions selected for the first operand. */
  secondOperandQuestionType!: QuestionFieldType;

  /** The value of the second operand. */
  secondOperand: RuleOperand = {
    type: OperandType.String,
    questionType: QuestionFieldType.ShortText,
    value: '',
    text: '',
  };

  /** The value of the first operand. */
  firstOperand: RuleOperand = {
    type: OperandType.Field,
    questionType: QuestionFieldType.ShortText,
    value: '',
    text: '',
  };

  /** Copy of first operand. */
  firstOperandCopy: RuleOperand | null = null;

  /** The operatorList to be shown. */
  operatorList: RuleModalOperator[] = [];

  /** The rule to be returned and added to new rulesArray. */
  conditionToAdd!: RuleEquation | null;

  /** Form for the second operative in case it is a customized value. */
  formSecondOperand!: FormGroup<{
    /** Form control field textFieldForm. */
    textFieldForm: FormControl<string | null>;
    /** Form control field numberFieldForm. */
    numberFieldForm: FormControl<string | null>;
    /** Form control field dateFieldForm. */
    dateFieldForm: FormControl<string | null>;
    /** Form control field selectFieldForm. */
    selectFieldForm: FormControl<string | null>;
    /** Form control field checkFieldForm. */
    checkFieldForm: FormControl<string | null>;
  }>;

  /** Enum operator Type. */
  operatorType = OperatorType;

  /** Enum operand type. */
  operandType = OperandType;

  /** Present Time by default to use in case of not have presentTime fields in the bucket. */
  presentTimeStatic: Question = {
    rithmId: uuidv4(),
    prompt: _.startCase(QuestionFieldType.PresentTime),
    questionType: QuestionFieldType.PresentTime,
    isReadOnly: false,
    isRequired: false,
    isPrivate: false,
    children: [],
    originalStationRithmId: this.stationRithmId,
  };

  /** Determine what fields are currently in the form condition the previous fields. */
  isCustomField = true;

  /** Whether Validate custom values text format. */
  validateFormat = true;

  /** The error if rules fails. */
  openFormCondition = false;

  /** Display the user added rules in the condition type. */
  showRuleItem = false;

  /** Display the form text if editing or not. */
  isEditForm = false;

  /** The rithmId of the first selected question to be compared. */
  firstOperandQuestionRithmId = '--';

  /** The rithmId of the second selected question to be compared if needed. */
  secondOperandQuestionRithmId = '';

  /** Set the text to show when the secondOperand is a field. */
  secondOperandQuestionPrompt = '';

  /** The information of the operator selected. */
  operatorSelected: OperatorType | null = null;

  /** Index of condition in current. */
  conditionIndexByEdit = 0;

  /** Is user loading info. */
  isLoadingUser = false;

  /** Is user error request info. */
  isErrorUser = false;

  /** Is loading request for condition. */
  isLoadingCondition = false;

  /** Is deleting request for condition. */
  isDeletingCondition = false;

  /** Data user. */
  dataUser: UserInfoBasicData | undefined;

  /** Custom-Id type(prefix or suffix). */
  customIdType: CustomIdType;

  /** Custom-Id suffix type(alpha or alphaNumeric or numeric). */
  suffixType: string | undefined;

  constructor(
    private containerService: ContainerService,
    private popupService: PopupService,
    private fb: FormBuilder,
    private userService: UserService,
    private powerService: PowerService,
  ) {
    this.formSecondOperand = this.fb.group({
      textFieldForm: this.fb.control(''),
      numberFieldForm: this.fb.control(''),
      dateFieldForm: this.fb.control(''),
      selectFieldForm: this.fb.control(''),
      checkFieldForm: this.fb.control(''),
    });
  }

  /** Listeners functions. */

  /**
   * Get the text field value.
   */
  getTextFieldValue(): void {
    this.textField?.textFieldForm.controls[
      this.secondOperandQuestionType
    ]?.valueChanges
      .pipe(takeUntil(this.cleanSecondOperandField$))
      .subscribe({
        next: (value: string) => {
          const formControl = this.formSecondOperand.controls.textFieldForm;
          const textField =
            this.textField?.textFieldForm.controls[
              this.secondOperandQuestionType
            ];

          formControl.setValue({
            [this.secondOperandQuestionType]: value,
          } as unknown as string);
          if (textField?.invalid) {
            formControl.setErrors(textField.errors);
          } else {
            formControl.setErrors(null);
          }
        },
      });
  }

  /**
   * Listen the answerSubject from documents.
   */
  subscribeDocumentAnswers(): void {
    //Gets from documentAnswer the value to be set to the second operand
    this.containerService.containerAnswer$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((answer: ContainerAnswer) => {
        const questionInfoTypes = [
          this.questionFieldType.CreatedBy,
          this.questionFieldType.UserSelect,
          this.questionFieldType.AssignedUser,
          this.questionFieldType.User,
        ];
        this.secondOperand.text =
          questionInfoTypes.includes(answer.type) && answer.label
            ? answer.label
            : answer.value;
        this.secondOperand.value = answer.value;
        this.secondOperand.questionType = questionInfoTypes.includes(
          answer.type,
        )
          ? answer.type
          : this.firstOperand.questionType;
        this.secondOperand.type =
          answer.type === QuestionFieldType.AssignedUser
            ? OperandType.String
            : OperandType.Field;
      });
  }

  /**
   * Load private/all Questions.
   */
  ngOnInit(): void {
    this.stationTerms.ContainerInfoItems.forEach((question) => {
      this.questionsInfoList.push(question.questionType as QuestionFieldType);
    });

    this.conditions.forEach((condition) => {
      condition.leftOperand.text =
        condition.rightOperand.questionType === QuestionFieldType.TimeInStation
          ? 'Time in Station (days)'
          : condition.leftOperand.text;
    });
    this.filteredBucketQuestions();
    this.subscribeDocumentAnswers();
    this.getTextFieldValue();
  }

  /**
   * Returns the second Operand to display in the last step.
   * @returns A normal value or a rithmId to display.
   */
  get customTypeForSecondOperand(): string {
    return ConditionHelper.getCustomTypeForSecondOperator(
      this.firstOperandQuestionType,
    );
  }

  /**
   * Get the list of questions for the first operand.
   * @returns Questions for the first operand options.
   */
  get firstOperandQuestionList(): Question[] {
    return this.conditionHelper.getStationQuestions(this.stationQuestionPool);
  }

  /**
   * Get the list of container info questions for the second operand.
   * @returns Questions of container info for the second operand options.
   */
  get firstOperandContainerInfoQuestion(): Question[] {
    return this.conditionHelper.getContainerInfoQuestions(
      this.stationQuestionPool,
    );
  }

  /**
   * Get the list of questions for the second operand.
   * @returns Questions for the second operand options.
   */
  get secondOperandQuestionList(): Question[] {
    let arrayFiltered: Question[] = [];

    if (this.firstOperandQuestionType === QuestionFieldType.File) {
      if (
        this.attachmentFieldLogicFlag &&
        this.operatorSelected &&
        [OperatorType.EqualTo, OperatorType.NotEqualTo].includes(
          this.operatorSelected,
        )
      ) {
        this.isCustomField = false;
      }
    }

    arrayFiltered = ConditionHelper.generateSecondOperandList(
      this.firstOperandQuestionType,
      this.stationQuestionPool,
      this.operatorSelected,
      this.firstOperand,
      this.attachmentFieldLogicFlag,
      this.showNumberFieldRules,
      this.customIdType,
      this.suffixType,
    );

    /**Only if a present time is required will it be added. */
    if (this.firstOperandQuestionType !== QuestionFieldType.PresentTime) {
      this.requiresStaticPresentTime(arrayFiltered) &&
        arrayFiltered.push(this.presentTimeStatic);
    }

    return arrayFiltered;
  }

  /**
   * Get name for LeftOperand.
   * @param condition Relationship condition.
   * @returns Relationship name.
   */
  getLeftOperandRelationship(condition: RuleEquation): string {
    let relation;
    for (const library of this.stationLibraryRelationships) {
      relation = library.relationships.find(
        (e) => e.rithmId === condition.leftOperand.value,
      );
      if (relation) return relation.name;
    }
    return '';
  }

  /**
   * Method for obtain basic data for user specific.
   * @param userRithmId User rithmId.
   */
  private getUserInfoBasic(userRithmId: string): void {
    this.isLoadingUser = true;
    this.isErrorUser = false;
    userRithmId &&
      this.userService
        .getUserInfoBasic(userRithmId)
        .pipe(first())
        .subscribe({
          next: (dataUser) => {
            this.dataUser = dataUser;
            const answer: QuestionAnswer = {
              questionRithmId: uuidv4(),
              referAttribute: '',
              asArray: [
                {
                  value: '',
                  isChecked: false,
                },
              ],
              asInt: 0,
              asDecimal: 0,
              asString: '',
              asDate: '',
              value: userRithmId,
              asJson: dataUser,
            };
            this.secondOperandDefaultQuestion.answer = answer;
            this.isLoadingUser = false;
            this.isErrorUser = false;
            this.secondOperand.text =
              dataUser.firstName + ' ' + dataUser.lastName;
          },
          error: () => {
            this.isLoadingUser = false;
            this.isErrorUser = true;
            this.notify('Error Getting user info. Please Try again', true);
            this.isErrorUser = false;
          },
        });
  }

  /**
   * Delete a single condition.
   * @param conditionRithmId Condition id.
   */
  deleteSpecificCondition(conditionRithmId: string): void {
    this.isDeletingCondition = true;
    this.powerService
      .deleteCondition(this.ruleRithmId, conditionRithmId)
      .pipe(first())
      .subscribe({
        next: () => {
          if (!this.orderOfOperations) {
            this.popupService.notify('Condition deleted correctly');
          }
          this.deleteConditionEmit.emit(conditionRithmId);
          this.isDeletingCondition = false;
        },
        error: () => {
          this.popupService.notify('Error deleting condition.', true);
          this.isDeletingCondition = false;
        },
      });
  }

  /**
   * Add or update action.
   */
  addOrUpdateCondition(): void {
    this.isLoadingCondition = true;
    this.powerService
      .addOrUpdateCondition(this.ruleRithmId, this.getConditionFromForm())
      .pipe(first())
      .subscribe({
        next: (conditionResponse) => {
          if (this.orderOfOperations) {
            this.popupService.notify('Condition saved successfully');
          }
          this.addCondition.emit(conditionResponse);
          this.closeForm();
          this.isLoadingCondition = false;
        },
        error: () => {
          this.isLoadingCondition = false;
        },
      });
  }

  /**
   * Notify and show pop-up for actions the admin.
   * @param message Message for show in popup.
   * @param isError If the popup is an error message.
   */
  private notify(message: string, isError = false): void {
    this.popupService.notify(message, isError);
  }

  /**
   * Request for all the bucket and Previous Questions.
   */
  private filteredBucketQuestions(): void {
    this.stationQuestionPool = this.stationQuestionPool.filter(
      (e) =>
        e.prompt !== 'Address Line 1' &&
        e.prompt !== 'Address Line 2' &&
        e.questionType !== QuestionFieldType.City &&
        e.questionType !== QuestionFieldType.State &&
        e.questionType !== QuestionFieldType.Zip,
    );
  }

  /**
   * Set operator list for the comparison type and set first operand type.
   * @param questionOrRelationSelected The field or relationship type to show the options of the corresponding operator list.
   * @param relationValue The value to send to backend when is relation.
   * @param specialType Informative question like Container Count, Time in Station and so.
   * @param customIdType The custom-Id type is prefix or suffix or undefined.
   */
  setFirstOperandInformation(
    questionOrRelationSelected?: Question | LibraryAvailableRelationship,
    relationValue: 'inwardName' | 'outwardName' | undefined = undefined,
    specialType = QuestionFieldType.ShortText,
    customIdType: CustomIdType = undefined,
  ): void {
    if (
      (questionOrRelationSelected &&
        'questionType' in questionOrRelationSelected) ||
      questionOrRelationSelected === undefined
    ) {
      const originalQuestion = this.stationQuestionPool.find(
        (q) => q.rithmId === this.firstOperandQuestionRithmId,
      );

      const specialQuestion = ComponentHelper.getSpecialField(specialType);
      //This will set a unique question id for special options.
      if (!questionOrRelationSelected) {
        specialQuestion.rithmId = uuidv4();
      }
      let customIdField = undefined;
      const { questionType, rithmId, prompt } =
        questionOrRelationSelected || specialQuestion;
      this.firstOperandQuestionType = questionType;
      this.secondOperandQuestionType = questionType;
      this.firstOperand.questionType = questionType;
      this.secondOperandDefaultQuestion.questionType = questionType;
      this.firstOperand.value = rithmId;
      this.firstOperand.text = prompt;
      this.operatorSelected = null;
      this.secondOperand.value = '';
      this.firstOperand.type =
        !questionOrRelationSelected ||
        this.questionsInfoList.includes(
          (questionOrRelationSelected as unknown as Question)?.questionType,
        )
          ? OperandType.Info
          : OperandType.Field;
      if (
        questionOrRelationSelected?.questionType === QuestionFieldType.CustomId
      ) {
        customIdField = JSON.parse(
          questionOrRelationSelected?.settings as string,
        ) as CustomIdSettings;
        this.customIdType = customIdType;
        this.suffixType = customIdField.suffixType;
      }
      this.operatorList = ConditionHelper.getOperatorList(
        questionType,
        this.operatorList,
        this.attachmentFieldLogicFlag,
        this.flagAttachmentsConditionsFilters,
        customIdType,
        customIdType === 'suffix' ? customIdField?.suffixType : undefined,
      );
      switch (questionOrRelationSelected?.questionType || specialType) {
        case QuestionFieldType.ShortText:
        case QuestionFieldType.URL:
        case QuestionFieldType.Email:
        case QuestionFieldType.Phone:
        case QuestionFieldType.LongText:
          this.secondOperand.type = OperandType.String;
          break;
        case QuestionFieldType.Number:
        case QuestionFieldType.Currency:
        case QuestionFieldType.TimeInStation:
        case QuestionFieldType.ContainerCount:
          this.secondOperand.type = OperandType.Number;
          break;
        case QuestionFieldType.Date:
        case QuestionFieldType.CreatedOn:
          this.secondOperandDefaultQuestion.settings =
            questionOrRelationSelected?.settings;
          this.secondOperand.type = OperandType.Date;
          break;
        case QuestionFieldType.PresentTime:
          this.secondOperand.type = OperandType.Info;
          break;
        case QuestionFieldType.CustomId:
          this.customIdType === 'suffix'
            ? (this.isCustomField = false)
            : (this.isCustomField = true);
          this.secondOperand.type = OperandType.Field;
          break;
        case QuestionFieldType.MultiSelect:
        case QuestionFieldType.Select:
        case QuestionFieldType.CheckList:
        case QuestionFieldType.RadioList:
        case QuestionFieldType.UserSelect:
          this.secondOperand.type = OperandType.String;
          this.secondOperandDefaultQuestion.settings =
            originalQuestion?.settings;
          if (questionOrRelationSelected) {
            this.secondOperandDefaultQuestion.possibleAnswers =
              questionOrRelationSelected.possibleAnswers;
            this.secondOperandDefaultQuestion.questionType =
              questionOrRelationSelected.questionType ===
              QuestionFieldType.CheckList
                ? QuestionFieldType.MultiSelect
                : questionOrRelationSelected.questionType;
          }
          break;
      }
    } else if (
      questionOrRelationSelected &&
      'inwardName' in questionOrRelationSelected
    ) {
      this.operatorList = ConditionHelper.getOperatorList(
        questionOrRelationSelected,
        this.operatorList,
        this.attachmentFieldLogicFlag,
        this.flagAttachmentsConditionsFilters,
      );
      this.firstOperand.text = questionOrRelationSelected[
        relationValue || 'inwardName'
      ]
        .toString()
        .replace('Name', '');

      this.secondOperand.type = OperandType.Relationship;
      this.operatorSelected = null;
      this.firstOperand.type = OperandType.Relationship;
      this.firstOperand.value = questionOrRelationSelected.rithmId;
      relationValue &&
        (this.secondOperand.value = relationValue.replace('Name', ''));
    }
  }

  /**
   * Check whether or not to add the Present Time to the previous questions.
   * @param arrayFiltered List of question filtered.
   * @returns A boolean.
   */
  requiresStaticPresentTime(arrayFiltered: Question[]): boolean {
    /** Check if there is any presentTime in the previous Question. */
    const checkPresentTime = arrayFiltered.find(
      (q) => q.questionType === QuestionFieldType.PresentTime,
    );
    return [QuestionFieldType.PresentTime].includes(
      this.firstOperand.questionType,
    ) && !checkPresentTime
      ? true
      : false;
  }

  /**
   * Set operator list for the comparison type and set first operand type.
   * @param questionSelected The field type to show the options of the corresponding operator list.
   */
  setSecondOperandInformation(questionSelected: Question): void {
    this.secondOperandQuestionPrompt = questionSelected.prompt;
    this.secondOperand.questionType = questionSelected.questionType;
    this.secondOperand.text = questionSelected.prompt;
    this.secondOperandDefaultQuestion.settings = questionSelected?.settings;
  }

  /**
   * Set The value for the current Rule.
   */
  setEquationContent(): void {
    this.conditionToAdd = this.getConditionFromForm();
    this.getOperandType(this.conditionToAdd);
    if (this.orderOfOperations) {
      this.addCondition.emit(this.conditionToAdd);
      this.addOrUpdateCondition();
    } else {
      this.conditions.push(this.conditionToAdd);
      this.somethingHasChanged.emit();
    }
    this.closeForm();
  }

  /**
   * Reset component field when a first operand is selected.
   */
  resetQuestionFieldComponent(): void {
    const isContains = [OperatorType.Contains].includes(
      this.operatorSelected as OperatorType,
    );

    this.validateFormat =
      this.firstOperandQuestionType === QuestionFieldType.URL
        ? [OperatorType.EqualTo, OperatorType.NotEqualTo].includes(
            this.operatorSelected as OperatorType,
          )
        : !isContains ||
          // Is contains and operand is phone number.
          (isContains &&
            (this.firstOperandQuestionType === QuestionFieldType.Phone ||
              this.firstOperandQuestionType === QuestionFieldType.Email));
    if (this.firstOperandQuestionType === QuestionFieldType.URL) {
      const questionType = ConditionHelper.getSecondOperandQuestionType(
        this.firstOperandQuestionType,
        this.operatorSelected as OperatorType,
      );
      this.secondOperandDefaultQuestion.questionType = questionType;
      this.secondOperandQuestionType = questionType;

      if (this.isCustomField) {
        this.secondOperand.value = '';
        this.secondOperandDefaultQuestion.value = '';
      }
    }

    switch (this.customTypeForSecondOperand) {
      case 'string':
        this.cleanSecondOperandField$.next();
        this.textField?.ngOnInit();
        this.getTextFieldValue();
        break;
      case 'number':
        this.numberField?.ngOnInit();
        break;
      case 'date':
        this.dateField?.ngOnInit();
        break;
      case 'select':
      case 'multiselect':
        this.selectField?.ngOnInit();
        break;
      case 'checklist':
        this.checkField?.ngOnInit();
        break;
      case 'assignedUser':
        this.userRelates?.ngOnInit();
        break;
    }
    if (
      this.firstOperandQuestionType === QuestionFieldType.File &&
      (this.operatorSelected === OperatorType.EqualTo ||
        this.operatorSelected === OperatorType.NotEqualTo)
    ) {
      this.isCustomField = false;
    }
  }

  /**
   * Reset Modal Values.
   */
  resetValues(): void {
    this.firstOperandQuestionRithmId = '--';
    this.firstOperand = {
      type: OperandType.Field,
      questionType: QuestionFieldType.ShortText,
      value: '',
      text: '',
    };
    this.resetValuesSecondOperand();
    this.operatorSelected = null;
    this.isEditForm = false;
    this.operatorList = [];
    this.firstOperandQuestionType = QuestionFieldType.ShortText;
    this.secondOperandQuestionType = QuestionFieldType.ShortText;
    this.secondOperandQuestionRithmId = '';
    this.secondOperandQuestionPrompt = '';

    this.secondOperandDefaultQuestion = {
      questionType: QuestionFieldType.ShortText,
      rithmId: uuidv4(),
      prompt: 'Custom',
      isReadOnly: false,
      isRequired: false,
      isPrivate: false,
      value: '',
      children: [],
      possibleAnswers: [],
      answer: {
        questionRithmId: uuidv4(),
        referAttribute: '',
        asArray: [
          {
            value: '',
            isChecked: false,
          },
        ],
        asJson: this.dataUser,
        asInt: 0,
        asDecimal: 0,
        asString: '',
        asDate: '',
        value: '',
      },
    };
    this.dataUser = undefined;
    this.selectField && this.selectField.ngOnInit();
  }

  /**
   * Close form add condition.
   */
  closeForm(): void {
    this.resetValues();
    this.openFormCondition = false;
  }

  /** Cancel condition. */
  cancelCondition(): void {
    if (
      this.conditions[this.conditionIndexByEdit]?.leftOperand?.questionType ===
      this.questionFieldType.PresentTime
    ) {
      this.conditions[this.conditionIndexByEdit].rightOperand.type =
        OperandType.Date;
      this.conditions[this.conditionIndexByEdit].rightOperand.value =
        this.conditions[this.conditionIndexByEdit].rightOperand.text;
    }
    this.closeForm();
    if (this.firstOperandCopy) {
      this.conditions[this.conditionIndexByEdit].leftOperand =
        this.firstOperandCopy;
    }
    this.firstOperandCopy = null;
  }

  /**
   * Reset second operand object values.
   */
  resetValuesSecondOperand(): void {
    this.secondOperand = {
      type: OperandType.String,
      questionType: QuestionFieldType.ShortText,
      value: '',
      text: '',
    };
    if (this.firstOperand.questionType === QuestionFieldType.Phone) {
      this.secondOperandDefaultQuestion.questionType = QuestionFieldType.Phone;
      this.secondOperandDefaultQuestion.answer = undefined;
    }
  }

  /**
   * Edit condition Form.
   * @param condition Condition to set in form.
   */
  editConditionForm(condition: RuleEquation): void {
    !this.orderOfOperations && this.closeForm();
    this.conditionToAdd = condition;
    this.isEditForm = true;
    this.operatorSelected = condition.operatorType;

    /** First Operand. */
    if (condition.leftOperand.type === OperandType.Relationship) {
      this.firstOperandQuestionRithmId =
        condition.leftOperand.value +
        (condition.rightOperand.value === 'inward' ? '-in' : '-out');
      /** If incoming field is CustomId, then assign `customIdType` and `suffixType` with respective value. */
    } else if (
      condition.leftOperand.questionType === QuestionFieldType.CustomId
    ) {
      const questionSelected = this.stationQuestionPool.find(
        ({ rithmId }) => rithmId === condition.leftOperand.value,
      );
      const customIdField = JSON.parse(
        questionSelected?.settings as string,
      ) as CustomIdSettings;
      if (
        ['prefix', 'suffix'].includes(condition.leftOperand.settings as string)
      ) {
        this.firstOperandQuestionRithmId =
          condition.leftOperand.value +
          (condition.leftOperand.settings === 'prefix' ? '-prefix' : '-suffix');
        this.customIdType =
          condition.leftOperand.settings === 'prefix' ? 'prefix' : 'suffix';
        this.suffixType = customIdField.suffixType;
      }
    } else {
      this.firstOperandQuestionRithmId = condition.leftOperand.value;
    }
    this.firstOperand = condition.leftOperand;
    this.firstOperandQuestionType = condition.leftOperand.questionType;
    this.firstOperandCopy = _.cloneDeep(condition.leftOperand);

    if (this.firstOperand.questionType === QuestionFieldType.Date) {
      this.secondOperandDefaultQuestion.settings = this.getSettingsDate(
        this.firstOperand.value,
      );
    }
    if (condition.leftOperand.type === OperandType.Relationship) {
      this.operatorList = ConditionHelper.relationshipGroup;
    } else {
      this.operatorList = ConditionHelper.getOperatorList(
        condition.leftOperand.questionType,
        this.operatorList,
        this.attachmentFieldLogicFlag,
        this.flagAttachmentsConditionsFilters,
        this.customIdType,
        this.suffixType,
      );
    }

    /** Second Operand. */
    this.secondOperand.value =
      condition.rightOperand.questionType !== QuestionFieldType.PresentTime
        ? condition.rightOperand.value
        : '';
    this.secondOperand.text = condition.rightOperand.text;
    this.secondOperand.questionType = condition.rightOperand.questionType;
    this.secondOperand.type = this.getOperandType(condition);

    this.conditionIndexByEdit = this.conditions.indexOf(condition);

    const hasRightOperand = this.secondOperandQuestionList.find(
      ({ rithmId }) => rithmId === condition.rightOperand.value,
    );

    if (hasRightOperand) {
      this.isCustomField = false;
      this.secondOperand.value = condition.rightOperand.value;
      this.secondOperand.text = condition.rightOperand.text;
      this.secondOperand.questionType = condition.rightOperand.questionType;
      this.secondOperand.type = this.getOperandType(condition);
    } else {
      this.secondOperandDefaultQuestion.value =
        condition.rightOperand.questionType !== QuestionFieldType.PresentTime
          ? condition.rightOperand.questionType === QuestionFieldType.UserSelect
            ? condition.rightOperand.value
            : this.secondOperand.value
          : '';

      this.secondOperandDefaultQuestion.questionType =
        condition.rightOperand.questionType;
      this.secondOperand.type = this.getOperandType(condition);
      this.secondOperandDefaultQuestion.answer?.value
        ? condition.rightOperand.questionType !== QuestionFieldType.PresentTime
          ? condition.rightOperand.text
          : ''
        : null;
    }

    this.openFormCondition = true;
    if (
      condition.rightOperand.questionType === QuestionFieldType.UserSelect &&
      condition.leftOperand.questionType !== QuestionFieldType.AssignedUser
    ) {
      const questionEdit: Question | undefined = this.stationQuestionPool.find(
        (q) => q.rithmId === this.firstOperandQuestionRithmId,
      );
      this.secondOperandDefaultQuestion.settings = questionEdit?.settings;
      this.getUserInfoBasic(this.secondOperandDefaultQuestion?.value || '');
    }
  }

  /**
   * Get operand type based on question type.
   * @param condition The selected rule.
   * @returns The operand type based on question type and string by default.
   */
  getOperandType(condition: RuleEquation): OperandType {
    /** Contains whether the first operand is some field of type text. */
    const isTextField = [
      QuestionFieldType.LongText,
      QuestionFieldType.ShortText,
    ].includes(this.firstOperandQuestionType);
    let operandType = OperandType.String;

    switch (condition.rightOperand.questionType) {
      case QuestionFieldType.Number:
      case QuestionFieldType.Currency:
      case QuestionFieldType.ContainerCount:
        operandType = isTextField ? OperandType.String : OperandType.Number;
        break;

      case QuestionFieldType.Date:
        operandType = isTextField ? OperandType.String : OperandType.Date;
        break;

      case QuestionFieldType.CreatedOn:
        operandType = isTextField ? OperandType.Info : OperandType.Date;
        break;

      case QuestionFieldType.AssignedUser:
        operandType = OperandType.Field;
        break;

      case QuestionFieldType.TimeInStation:
        operandType = isTextField ? OperandType.Info : OperandType.Number;
        break;

      case QuestionFieldType.ContainerName:
      case QuestionFieldType.StationName:
      case QuestionFieldType.StationId:
      case QuestionFieldType.ContainerId:
      case QuestionFieldType.ParentContainerRithmId:
      case QuestionFieldType.ParentStationRithmId:
      case QuestionFieldType.CreatedBy:
      case QuestionFieldType.PresentTime:
        operandType = OperandType.Info;
        break;

      case QuestionFieldType.CheckList:
      case QuestionFieldType.MultiSelect:
      case QuestionFieldType.Select:
      case QuestionFieldType.RadioList:
        operandType = OperandType.String;
        // eslint-disable-next-line no-case-declarations
        const queIndex = this.firstOperandQuestionList.findIndex(
          (question) => question.rithmId === condition.leftOperand.value,
        );
        this.secondOperandDefaultQuestion.possibleAnswers =
          this.firstOperandQuestionList[queIndex]?.possibleAnswers;

        this.secondOperandDefaultQuestion.questionType =
          condition.leftOperand.questionType === QuestionFieldType.CheckList
            ? QuestionFieldType.MultiSelect
            : condition.leftOperand.questionType;
        break;
      default:
        operandType = OperandType.String;
        break;
    }
    const questionInfoTypes = [
      QuestionFieldType.StationName,
      QuestionFieldType.StationId,
      QuestionFieldType.ContainerName,
      QuestionFieldType.TimeInStation,
      QuestionFieldType.CreatedBy,
      QuestionFieldType.CreatedOn,
      QuestionFieldType.PresentTime,
      QuestionFieldType.ParentContainerRithmId,
      QuestionFieldType.ParentStationRithmId,
      QuestionFieldType.ContainerId,
    ];

    if (questionInfoTypes.includes(condition.leftOperand.questionType)) {
      condition.leftOperand.type = OperandType.Info;
    }

    if (questionInfoTypes.includes(condition.rightOperand.questionType)) {
      if (this.isCustomField) {
        condition.rightOperand.type = OperandType.String;
      } else {
        condition.rightOperand.type = OperandType.Info;
      }
      condition.rightOperand.value = this.secondOperand.value.toString();
    } else if (condition.leftOperand.type === OperandType.Relationship) {
      condition.rightOperand.type = OperandType.Relationship;
      condition.rightOperand.text = condition.rightOperand.value;
    } else if (this.isCustomField) {
      if (
        condition.leftOperand.questionType === QuestionFieldType.Number ||
        condition.leftOperand.questionType === QuestionFieldType.Currency
      ) {
        condition.rightOperand.type = OperandType.Number;
      } else if (
        condition.leftOperand.questionType === QuestionFieldType.Date
      ) {
        condition.rightOperand.type = OperandType.Date;
      } else {
        condition.rightOperand.type = OperandType.String;
      }
    } else {
      condition.rightOperand.type = OperandType.Field;
    }
    if (
      condition.operatorType === OperatorType.IsAnswered ||
      condition.operatorType === OperatorType.NotAnswered
    ) {
      condition.rightOperand.type = OperandType.String;
    }
    // If Question Type is Assigned to, then right operand type is always `info` as adding custom data is not possible.
    if (condition.leftOperand.questionType === QuestionFieldType.AssignedUser) {
      condition.rightOperand.type = OperandType.Info;
    }
    this.assignValueToQuestion(condition);
    return operandType;
  }

  /**
   * Assigns the rule value to the answer of the selected question.
   * @param condition The selected rule.
   */
  assignValueToQuestion(condition: RuleEquation): void {
    switch (condition.rightOperand.questionType) {
      case QuestionFieldType.Number:
      case QuestionFieldType.ContainerCount:
      case QuestionFieldType.TimeInStation:
      case QuestionFieldType.Function:
        if (this.secondOperandDefaultQuestion.answer) {
          this.secondOperandDefaultQuestion.answer.value =
            condition.rightOperand.value;
          this.secondOperandDefaultQuestion.answer.referAttribute = 'asInt';
          this.secondOperandDefaultQuestion.answer.asInt = Number(
            condition.rightOperand.value,
          );
        }
        break;

      case QuestionFieldType.Currency:
        if (this.secondOperandDefaultQuestion.answer) {
          this.secondOperandDefaultQuestion.answer.value =
            condition.rightOperand.value;
          this.secondOperandDefaultQuestion.answer.referAttribute = 'asDecimal';
          this.secondOperandDefaultQuestion.answer.asDecimal = Number(
            condition.rightOperand.value,
          );
        }
        break;

      case QuestionFieldType.Phone:
        if (this.secondOperandDefaultQuestion.answer) {
          this.secondOperandDefaultQuestion.answer.value =
            condition.rightOperand.value;
          this.secondOperandDefaultQuestion.answer.referAttribute = 'asString';
          this.secondOperandDefaultQuestion.answer.asString =
            condition.rightOperand.value;
        }
        break;
      case QuestionFieldType.Date:
      case QuestionFieldType.CreatedOn:
        if (this.secondOperandDefaultQuestion.answer) {
          this.secondOperandDefaultQuestion.answer.value =
            condition.rightOperand.value;
          this.secondOperandDefaultQuestion.answer.asDate =
            condition.rightOperand.value;
        }

        if (condition.rightOperand.type === OperandType.Info) {
          condition.rightOperand.value = this.secondOperand.value.toString();
        }
        break;

      case QuestionFieldType.CheckList:
      case QuestionFieldType.MultiSelect:
      case QuestionFieldType.Select:
      case QuestionFieldType.RadioList:
        // eslint-disable-next-line no-case-declarations
        let arrOptions: AnswerAsArray[] = [];
        if (
          [QuestionFieldType.MultiSelect, QuestionFieldType.CheckList].includes(
            condition.rightOperand.questionType,
          )
        ) {
          const options = condition.rightOperand.text.split('|');
          options.forEach((e) => {
            arrOptions.push({ value: e, isChecked: true });
          });
        } else {
          arrOptions = [
            { value: condition.rightOperand.text, isChecked: true },
          ];
        }

        if (this.secondOperandDefaultQuestion.answer?.asArray) {
          this.secondOperandDefaultQuestion.answer.asArray = arrOptions;
        }
        break;

      case QuestionFieldType.ContainerName:
      case QuestionFieldType.StationName:
      case QuestionFieldType.StationId:
      case QuestionFieldType.ContainerId:
      case QuestionFieldType.ParentContainerRithmId:
      case QuestionFieldType.ParentStationRithmId:
      case QuestionFieldType.CreatedBy:
      case QuestionFieldType.PresentTime:
        condition.rightOperand.value = this.secondOperand.value.toString();
        break;

      default:
        if (this.secondOperandDefaultQuestion.answer) {
          this.secondOperandDefaultQuestion.answer.value =
            condition.rightOperand.value;
        }
    }
  }

  /**
   * Edit condition Form.
   *
   */
  saveChanges(): void {
    const index = this.conditions.indexOf(this.conditionToAdd as RuleEquation);
    if (index !== -1) {
      this.conditionToAdd = this.getConditionFromForm();
      this.getOperandType(this.conditionToAdd);
      if (
        this.conditionToAdd.operatorType === OperatorType.IsAnswered ||
        this.conditionToAdd.operatorType === OperatorType.NotAnswered
      ) {
        this.conditionToAdd.rightOperand = {
          type: OperandType.String,
          questionType: this.conditionToAdd.leftOperand.questionType,
          value: '',
          text: '',
        };
      }

      this.conditions[index] = this.conditionToAdd;
    }
    this.isEditForm = false;
    this.openFormCondition = false;
    this.conditionToAdd = null;
    this.somethingHasChanged.emit();
  }

  /**
   * Return Condition with form values.
   * @returns RuleEquation from form.
   */
  getConditionFromForm(): RuleEquation {
    const rightOperandType = ConditionHelper.getRightOperandType(
      this.firstOperand,
      this.secondOperand,
      this.isCustomField,
    );
    const index = this.conditions.indexOf(this.conditionToAdd as RuleEquation);
    // If it comes with relation or customId we replace, otherwise id by default.
    const firstOperandIdValue =
      this.firstOperand.type === OperandType.Relationship
        ? this.firstOperandQuestionRithmId.replace(/-in$|-out$/gi, '')
        : this.firstOperand.questionType === QuestionFieldType.CustomId
          ? this.firstOperandQuestionRithmId.replace(/-prefix$|-suffix$/gi, '')
          : this.firstOperandQuestionRithmId;

    if (this.firstOperandQuestionType === QuestionFieldType.URL) {
      this.secondOperand.questionType =
        ConditionHelper.getSecondOperandQuestionType(
          this.firstOperandQuestionType,
          this.operatorSelected as OperatorType,
        );
    }

    const ruleEquation: RuleEquation = {
      rithmId: this.isEditForm
        ? this.conditions[index]
          ? this.conditions[index].rithmId
          : uuidv4()
        : uuidv4(),
      leftOperand: ConditionHelper.getLeftOperand(
        this.firstOperand,
        firstOperandIdValue,
      ),
      operatorType:
        (this.operatorSelected as OperatorType) || OperatorType.EqualTo,
      rightOperand: ConditionHelper.getRightOperand(
        rightOperandType,
        this.secondOperand,
        // If any of these operators are included, the right operands are emptied.
        [OperatorType.IsAnswered, OperatorType.NotAnswered].includes(
          this.operatorSelected as OperatorType,
        ),
      ),
    };
    // If the incoming field is CustomId, prefix/suffix should be added to `settings` property.
    if (this.firstOperand.questionType === QuestionFieldType.CustomId) {
      ruleEquation.leftOperand = {
        ...ruleEquation.leftOperand,
        settings: this.customIdType,
      };
    }
    return ruleEquation;
  }

  /**
   * Popup confirmation remove condition.
   * @param index Index of the element to remove.
   * @param conditionRithmId Condition rithmId.
   */
  async confirmRemoveCondition(
    index: number,
    conditionRithmId: string,
  ): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Are you sure?',
      message: 'This condition will be removed',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (response) {
      this.orderOfOperations
        ? this.deleteSpecificCondition(conditionRithmId)
        : this.removeCondition(index);
    }
    if (this.orderOfOperations) {
      this.popupService.notify('Condition removed successfully');
    }
  }

  /**
   * Remove condition.
   * @param index Index of the element to remove.
   */
  removeCondition(index: number): void {
    this.conditions.splice(index, 1);
    this.somethingHasChanged.emit();
  }

  /**
   * Remove condition.
   * @param rithmId Index of the element to remove.
   * @returns Settings.
   */
  getSettingsDate(rithmId: string): string {
    return (
      this.stationQuestionPool.find((q) => q.rithmId === rithmId)?.settings ||
      ''
    );
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.cleanSecondOperandField$.next();
    this.cleanSecondOperandField$.complete();
  }
}
